import React from 'react';

const GoalItemContent = ({ goal }) => (
  <div className="flex flex-col justify-center items-center">
    <div className="font-medium mb-2 text-center">{goal.summary}</div>
  </div>
);

const GoalItem = ({ goal }) => (
  <div className="bg-gray-00 px-5 py-4 rounded-md w-72 min-h-28 flex flex-col justify-center border border-navy-01">
    {goal.category === 'Other' ? (
      <div className="flex flex-col justify-center items-center">
        <div className="font-medium text-center">{goal.name}</div>
      </div>
    ) : (
      <GoalItemContent goal={goal} />
    )}
  </div>
);

export default GoalItem;
