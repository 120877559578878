import React from 'react';
import CustomButton from 'components/partials/custom-button/custom-button';

const GoalContactItem = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:clientsupport@marketviewedu.com';
  };

  return (
    <div className="w-full bg-indigo-02 py-8 px-4 text-white rounded-md flex flex-col items-center mt-10">
      <h3 className="text-xl font-bold mb-2">Are your goals missing?</h3>
      <p className="mb-4 text-navy-01">
        Contact your Client Success Team to discuss your priorities and ensure
        your goals are fully aligned and up to date.
      </p>
      <CustomButton variant="primary" size="sm" onClick={handleEmailClick}>
        Speak to Us
      </CustomButton>
    </div>
  );
};

export default GoalContactItem;
