import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import classNames from 'classnames';

import Icons from 'assets/icons';

import { useGoalForm } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/context/context';
import useInitialize from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/hooks/useInitialize';

import Select from 'components/partials/custom-select/custom-select';
import CustomRadioInput from 'components/partials/custom-radio-input/custom-radio-input';
import Textarea from 'components/partials/textarea/textarea';
import FormattedTextInput from 'components/partials/formatted-input/formatted-input';
import DetailFields from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/goal-details/goal-details';
import CustomButton from 'components/partials/custom-button/custom-button';
import OtherGoalForm from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/other-goal-form/other-goal-form';
import SummaryTextarea from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/components/summary-textarea';

import { prepareGoalPayload } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goals-manager.helper';

import { schema } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/goals-configuration';

import {
  getValueLabel,
  validateValue,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/goal-form.helpers';

import { TYPES_WITHOUT_SUFFIXES } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-tab.constants';
import api from 'api';

const radioOptions = [
  { label: 'Increase', value: 'Increase', colorClass: 'text-success-06' },
  { label: 'Maintain', value: 'Maintain', colorClass: 'text-gray-04' },
  { label: 'Decrease', value: 'Decrease', colorClass: 'text-red-00' },
];

const GoalForm = ({
  type,
  category,
  isEditing,
  data,
  isCreation,
  userId,
  enteringClassYear,
}) => {
  const [generationLoading, setGenerationLoading] = useState(false);
  const { control, setValue, reset, clearErrors, loading, getValues } =
    useGoalForm();

  const parsedType = type === 'Academic Quality' ? type.replace(' ', '') : type;

  const [valueType, setValueType] = useState(schema[parsedType]?.valueType);
  const isDisabled = (data && !isEditing) || generationLoading;

  const initializeFormValues = useInitialize(type, setValue);

  const {
    fields: detailFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'details',
  });

  const generateSummary = async () => {
    setGenerationLoading(true);

    const formData = getValues();

    const payload = prepareGoalPayload({
      userId,
      data: { ...data, type, category, enteringClassYear },
      formData,
    });

    try {
      const response = await api.generateGoalSummary({
        userId,
        goalData: payload.goalData,
      });

      if (response?.data?.summary) {
        setValue('summary', response.data.summary);
        clearErrors('summary');
      }
    } catch (error) {
      console.error('Error while generating AI description', error);
    } finally {
      setGenerationLoading(false);
    }
  };

  useEffect(() => {
    if (loading) return;

    if (category) {
      reset();

      if (data) {
        data.details.forEach((item) => append({}));
      }

      if (isCreation) {
        append({});
      }

      if (data && !isCreation) {
        initializeFormValues(data.details, data);
      }
    }
  }, [category, type, data, loading, isCreation]);

  //Handling value type based on subType
  useEffect(() => {
    const selectedSubType = schema[parsedType]?.subType?.find(
      (opt) => opt.value === data?.subType
    );

    if (selectedSubType) {
      setValueType(selectedSubType.valueType);
    } else {
      setValueType(schema[parsedType]?.valueType);
    }
  }, [data?.subType, type]);

  const renderOptions = (options) =>
    options.map((option) => ({ value: option.value, label: option.label }));

  const getSelectedOptionValue = (options, value) =>
    options.find((opt) => opt.value === value);

  const handleSelectChange = (
    selectedOption,
    parsedType,
    schema,
    onChange,
    setValueType
  ) => {
    onChange(selectedOption.value);

    const newValueType = schema[parsedType].subType.find(
      (opt) => opt.value === selectedOption.value
    )?.valueType;

    setValueType(newValueType || schema[parsedType].valueType);
  };

  const renderDetailFields = () => {
    const isCombined = category === 'Combined';
    const isEditable = isCombined && (isEditing || isCreation);

    return (
      <>
        {detailFields.map((item, detailIndex) => (
          <div
            key={item.id}
            className={classNames({
              'flex gap-3 justify-start items-start w-full': isCombined,
            })}
          >
            <DetailFields
              detailIndex={detailIndex}
              detailData={item}
              category={category}
              type={parsedType}
              isDisabled={isDisabled}
            />
            {isEditable && detailFields.length > 1 && (
              <CustomButton
                onClick={() => remove(detailIndex)}
                customClass="!h-auto !p-0"
              >
                <Icons.XinCircle className="text-blue-03 h-5 w-5 mt-14" />
              </CustomButton>
            )}
          </div>
        ))}
        {isEditable && (
          <CustomButton
            type="button"
            onClick={() => append({})}
            variant="secondary"
            iconAfter={<Icons.PlusInCircle className="ml-1 w-4 h-4" />}
            size="sm"
            customClass="mt-3"
          >
            and
          </CustomButton>
        )}
      </>
    );
  };

  return (
    <form>
      {category === 'Other' ? (
        <OtherGoalForm {...{ isDisabled }} />
      ) : (
        <>
          <div className="flex gap-3 w-full flex-wrap">
            <Select
              label="Goal type"
              labelInTop={true}
              value={{ label: type, value: type }}
              options={[]}
              disabled
              customSelectWrapperClass="max-w-[150px]"
            />

            {schema[parsedType]?.subType && (
              <Controller
                name="subType"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    value={getSelectedOptionValue(
                      schema[parsedType].subType,
                      value
                    )}
                    onChange={(selectedOption) =>
                      handleSelectChange(
                        selectedOption,
                        parsedType,
                        schema,
                        onChange,
                        setValueType
                      )
                    }
                    options={renderOptions(schema[parsedType].subType)}
                    disabled={isDisabled}
                    customSelectWrapperClass="max-w-[150px]"
                    labelInTop={true}
                    label="Sub Type"
                    fieldError={
                      error && { ...error, message: 'This field is required' }
                    }
                    isClearable={false}
                    clearInputErr={() => clearErrors('subType')}
                    required={true}
                  />
                )}
              />
            )}
            {category !== 'Combined' && renderDetailFields()}

            <Controller
              name="value"
              control={control}
              rules={{
                validate: (value) => validateValue(value, valueType),
              }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <FormattedTextInput
                    {...field}
                    placeholder="Define..."
                    disabled={isDisabled}
                    inputWrapperCustomClass="max-w-[150px]"
                    label={getValueLabel(valueType)}
                    labelInTop={true}
                    disabledClassName="bg-gray-06"
                    errorsNames={error}
                    withSuffix={!TYPES_WITHOUT_SUFFIXES.includes(type)}
                    ref={null}
                    required={true}
                  />
                </div>
              )}
            />
          </div>

          {category === 'Combined' && renderDetailFields()}

          <div className="mt-4 flex">
            <Controller
              name="relativeToLastYear"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  {radioOptions.map((option) => (
                    <CustomRadioInput
                      key={option.value}
                      labelFor={option.value}
                      label={option.label}
                      radioValue={option.value}
                      labelClass={`${option.colorClass} font-semibold`}
                      setAction={field.onChange}
                      onChange={field.onChange}
                      disabled={isDisabled}
                      {...field}
                    />
                  ))}
                </>
              )}
            />
          </div>
          <div className="flex items-center gap-2 mt-5">
            <CustomButton
              variant="secondary"
              onClick={generateSummary}
              disabled={isDisabled || generationLoading}
              size="sm"
              iconBefore={<Icons.Ai customClass="h-4 w-4" />}
              type="button"
            >
              Generate description
            </CustomButton>
          </div>

          <div className="mt-4">
            <Controller
              name="summary"
              control={control}
              rules={{ required: 'This field is required' }}
              disabled={isDisabled || generationLoading}
              render={({ field, fieldState: { error } }) => (
                <SummaryTextarea
                  {...field}
                  textareaWrapperStyles="max-w-[600px]"
                  label="AI Description"
                  rows={3}
                  loading={generationLoading}
                  helperText="The AI Description will be used on the Client’s Goals page. It is editable."
                  errorsNames={error}
                  required={true}
                />
              )}
            />
          </div>
        </>
      )}

      <div className="mt-5">
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              textareaWrapperStyles="max-w-[600px]"
              label="Note"
              placeholder="Use this area to leave a note..."
              disabled={isDisabled}
              rows={3}
            />
          )}
        />
      </div>
    </form>
  );
};

export default React.memo(GoalForm);
