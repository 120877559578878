import { useCallback } from 'react';

const useInitialize = (type, setValue) =>
  useCallback(
    (details = [], data) => {
      const setFormValue = (key, value, detailIndex) => {
        const initializedValue =
          Array.isArray(value) && value.length === 1 ? value[0] : value;

        setValue(`details.${detailIndex}.${key}`, initializedValue);
      };

      details.forEach((dataField, detailIndex) => {
        Object.keys(dataField).forEach((key) => {
          setFormValue(key, dataField[key], detailIndex);
        });
      });

      [
        'value',
        'relativeToLastYear',
        'note',
        'subType',
        'name',
        'description',
        'summary',
      ].forEach((fieldName) => setValue(fieldName, data[fieldName] || ''));
    },
    [setValue, type]
  );

export default useInitialize;
