import {
  HEADERS,
  ROWS,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-tab.constants';

export const getPathToGoal = (path) => {
  const pathSegments = path.split('/').filter((segment) => segment !== '');

  if (pathSegments.length > 0) {
    pathSegments.pop();
  }

  const newPath = `/${pathSegments.join('/')}`;

  return newPath;
};

export const getLastPathSegment = (path) => {
  const pathSegments = path.split('/').filter((segment) => segment !== '');

  return !!pathSegments.length ? +pathSegments[pathSegments.length - 1] : '';
};

export const prepareGoalPayload = ({ userId, clientId, data, formData }) => {
  const id = Number(data.id) || undefined;

  const payload = {
    userId,
    clientId,
    id,
    goalData: {
      category: data.category,
      type: data.type,
      id,
      enteringClassYear: data.enteringClassYear,
      value: Number(formData.value),
      relativeToLastYear: formData.relativeToLastYear,
      note: formData.note,
      summary: formData.summary,
      ...(formData.subType && { subType: formData.subType }),
      name: formData.name,
      description: formData.description,
      details: formData.details.map((item) => ({
        ...item,
        options: item.options ? [].concat(item.options) : [],
        category: item.category || data.category,
      })),
    },
  };

  return payload;
};

export const getUrlParams = ({
  goalType,
  goalCategory,
  year,
  goalId,
  clientId,
}) => ({
  type: HEADERS[goalType],
  category: ROWS[goalCategory],
  enteringClassYear: Number(year),
  goalId,
  clientId,
});
