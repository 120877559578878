import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import api from 'api';
import powerbi, { pbiModels } from 'utils/powerbi';
import ErrorWidget from 'containers/user/home/components/widgets/error-widget';
import NavButton from 'containers/user/home/components/shared/navigation-button';

import { useReports } from 'state/reports/reports.thunk';

export const powerBiWidgetConfig = {
  colSpan: 2,
  rowSpan: {
    default: 9,
  },
};

const Skeleton = () => (
  <div className="animate-pulse w-full h-full flex flex-col space-y-4 p-4 bg-gray-50 rounded-lg">
    <div className="h-6 bg-gray-300 rounded w-1/3"></div>
    <div className="h-4 bg-gray-300 rounded w-2/3"></div>
    <div className="h-48 bg-gray-200 rounded"></div>
    <div className="h-4 bg-gray-300 rounded w-1/4"></div>
  </div>
);

const PowerBiWidget = ({ userId, widgetId, customClass }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [widgetData, setWidgetData] = useState(null);
  const [relatedReport, setRelatedReport] = useState();

  const reports = useReports();

  useEffect(() => {
    reports?.loadIfMissing();
  }, [reports]);

  const visualRef = useRef();

  const loadWidgetData = async () => {
    setLoading(true);
    try {
      const response = await api.getWidget({ userId, id: widgetId });
      const data = response.data.widget.content;

      if (reports.data?.length && data.powerbiRelatedReportId) {
        setRelatedReport(
          reports.data.find(
            (rpt) => rpt.powerBiId === data.powerbiRelatedReportId
          )
        );
      }

      if (data) setWidgetData(data);
    } catch (err) {
      console.error('Error fetching widget data:', err);
      setError(err);
      setLoading(false);
    }
  };

  const loadVisual = () => {
    if (!widgetData || !visualRef.current) return;

    const { powerbiToken, powerbiReportId, powerbiPageId, powerbiVisualId } =
      widgetData;

    const embedConfiguration = {
      type: 'visual',
      id: powerbiReportId,
      embedUrl: 'https://app.powerbi.com/visualEmbed',
      tokenType: pbiModels.TokenType.Embed,
      accessToken: powerbiToken.token,
      pageName: powerbiPageId,
      visualName: powerbiVisualId,
      settings: {
        background: pbiModels.BackgroundType.Transparent,
        navContentPaneEnabled: false,
        filterPaneEnabled: false,
      },
    };

    try {
      const visual = powerbi.embed(visualRef.current, embedConfiguration);

      visual.on('rendered', () => setLoading(false));
      visual.on('error', (errorObject) => setError(errorObject.detail));
    } catch (e) {
      console.error('Error embedding Power BI visual:', e);
      setError(e);
    }
  };

  useEffect(() => {
    if (error) return;

    if (widgetData) {
      loadVisual();
    } else {
      loadWidgetData();
    }
  }, [widgetData, error, reports.data]);

  useEffect(() => {
    if (visualRef.current && !error) {
      powerbi.bootstrap(visualRef.current, { type: 'visual' });
    }
  }, [error]);

  if (error) {
    return (
      <div className="p-5 h-full border border-gray-05 rounded-lg">
        <ErrorWidget />
      </div>
    );
  }

  return (
    <div className="relative h-full p-5 border border-gray-05 flex flex-col rounded-lg gap-2.5">
      {loading && (
        <div
          className={classNames(
            'absolute inset-0 flex items-center justify-center bg-white z-10 rounded-lg transition-opacity duration-300',
            { 'opacity-0 pointer-events-none': !loading }
          )}
        >
          <Skeleton />
        </div>
      )}
      <div
        ref={visualRef}
        className={classNames(
          'h-full transition-opacity duration-300',
          customClass,
          { 'opacity-0 pointer-events-none': loading }
        )}
      ></div>
      {relatedReport && (
        <NavButton
          link={`/reports/${relatedReport?.id}/report-details`}
          text="View report"
          state={{
            powerbiRelatedReportPageId: widgetData.powerbiRelatedReportPageId,
          }}
        />
      )}
    </div>
  );
};

export default PowerBiWidget;
