import React, { useState, useEffect } from 'react';

import Icons from 'assets/icons';

const GoalsCarusel = ({ goals }) => {
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const currentGoal = goals[currentGoalIndex];

  const handleNext = () => {
    setIsPaused(true);
    setCurrentGoalIndex((prevIndex) => (prevIndex + 1) % goals.length);
  };

  const handlePrev = () => {
    setIsPaused(true);
    setCurrentGoalIndex(
      (prevIndex) => (prevIndex - 1 + goals.length) % goals.length
    );
  };

  const isSingleGoal = goals.length === 1;

  useEffect(() => {
    if (isSingleGoal || isPaused) return;

    const interval = setInterval(() => {
      setCurrentGoalIndex((prevIndex) => (prevIndex + 1) % goals.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [goals.length, isPaused, isSingleGoal]);

  return (
    <div className="flex-1 bg-gray-00 p-4 rounded-lg flex items-center justify-between">
      {!isSingleGoal && (
        <button
          onClick={handlePrev}
          className="text-blue-04 hover:text-blue-06 transition"
          aria-label="Previous goal"
        >
          <Icons.Arrow className="h-5 w-5 rotate-180" />
        </button>
      )}

      <div className="flex-1 flex flex-col justify-center items-center text-gray-03">
        <p className="font-semibold text-center text-lg mb-2 px-1">
          {currentGoal.summary}
        </p>
      </div>

      {!isSingleGoal && (
        <button
          onClick={handleNext}
          className="text-blue-04 hover:text-blue-06 transition"
          aria-label="Next goal"
        >
          <Icons.Arrow className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export default GoalsCarusel;
