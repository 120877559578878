import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

import Loader from 'components/partials/loader/loader';

import api from 'api';

import { useOkta } from 'utils/okta/use-okta';
import powerbi, { pbiModels } from 'utils/powerbi';

function initPowerBi(reportRef) {
  powerbi.bootstrap(reportRef.current, {
    type: 'report',
  });
}

const PowerBiReport = ({
  reportId,
  onError,
  customSettings,
  relatedPageId,
  customClass = '',
  ...props
}) => {
  const reportRef = useRef();
  const okta = useOkta();

  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const loadReport = () => {
    setLoading(true);

    api
      .getReportToken(reportId)
      .then((response) => {
        setLoading(false);

        var embedConfiguration = {
          type: 'report',
          id: reportId,
          embedUrl: 'https://app.powerbi.com/reportEmbed',
          tokenType: pbiModels.TokenType.Embed,
          accessToken: response.data.token,
          viewMode: pbiModels.ViewMode.View,
          permissions: pbiModels.Permissions.Read,
          pageName: relatedPageId,
          settings: {
            background: pbiModels.BackgroundType.Transparent,
            navContentPaneEnabled: false, // Hide bottom bar
            filterPaneEnabled: false,
            // Hide hover filters
            visualSettings: {
              visualHeaders: [
                {
                  settings: {
                    // visible: false
                  },
                  /* No selector - Hide visual header for all the visuals in the report */
                },
              ],
            },

            ...customSettings,
          },
        };

        try {
          const report = powerbi.embed(reportRef.current, embedConfiguration);

          // Used to check if users session has timed out
          report.on('rendered', (report) => {
            // Set iframe title for a11y, powerbi does not seem to provide any way to set this
            // through the library
            document
              .querySelectorAll('iframe')
              .forEach((iframeNode) => iframeNode.setAttribute('sandbox', ''));
            document
              .querySelector('iframe')
              .setAttribute('title', 'MARKETview Report');

            // On render manually refresh token so token is active if navigate to another page
            let tokenToRenew = JSON.parse(
              localStorage.getItem('okta-token-storage')
            );
            let accessTokenExpiration =
              tokenToRenew &&
              tokenToRenew['accessToken'] &&
              tokenToRenew['accessToken']['expiresAt'] * 1000;

            if (Date.now() < accessTokenExpiration) {
              // Token is still active so refresh
              okta.token
                .renew(tokenToRenew['accessToken'])
                .then((freshTokens) => {
                  const accessToken = freshTokens.find((token) =>
                    token.hasOwnProperty('accessToken')
                  );
                  const idToken = freshTokens.find((token) =>
                    token.hasOwnProperty('idToken')
                  );

                  localStorage.setItem(
                    'okta-token-storage',
                    JSON.stringify({
                      accessToken: accessToken,
                      idToken: idToken,
                    })
                  );

                  // Updating the token hash in the BE
                  api.postLoginNotifyBackend();
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              // Token is expired so timeout session
              okta.signOut();
              window.location.reload();
            }
          });

          // Print PBI errors
          report.on('error', (errorObject) => {
            const err = errorObject.detail;

            console.log(
              `Error occurred: ${err.message}. Detailed message: ${err.detailedMessage}`
            );
            console.log(err);
          });
        } catch (e) {
          // If we navigate away quickly, this can throw an error.
          console.error('Error loading PowerBI report', e);
        }
      })
      .catch((error) => {
        setError(error);

        return onError && onError(error);
      });
  };

  useEffect(() => {
    initPowerBi(reportRef);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadReport();
    // eslint-disable-next-line
  }, [reportId]);

  if (error) {
    return null;
  }

  const classes = classNames('border-none', {
    invisible: loading,
  });

  return (
    <>
      <Loader {...{ loading: loading, fullScreen: false }} />
      <div className={classes}>
        <div
          ref={reportRef}
          className={`border-none ${customClass}`}
          {...props}
        ></div>
      </div>
    </>
  );
};

export default PowerBiReport;
