import React, { useState, useEffect, forwardRef } from 'react';

import Textarea from 'components/partials/textarea/textarea';

const SummarizeTextarea = forwardRef(({ loading, value, ...props }, ref) => {
  const [placeholder, setPlaceholder] = useState('');
  const [displayValue, setDisplayValue] = useState(value || '');

  useEffect(() => {
    if (loading) {
      setPlaceholder('Generating');
      let dots = '';
      const interval = setInterval(() => {
        dots = dots.length < 3 ? dots + '.' : '';
        setPlaceholder(`Generating${dots}`);
      }, 500);

      return () => clearInterval(interval);
    } else {
      setPlaceholder('Generate the text by clicking the button above...');
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      setDisplayValue(value || '');
    }
  }, [value, loading]);

  return (
    <Textarea
      {...props}
      ref={ref}
      value={loading ? '' : displayValue}
      placeholder={placeholder}
    />
  );
});

export default SummarizeTextarea;
